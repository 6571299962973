<template>
  <div
    class="daily-tracker card p-2 mr-3 flex-shrink-0 d-flex flex-column justify-content-center"
  >
    <span class="daily-tracker__day text-muted d-block">{{
      trackedData.day
    }}</span>
    <strong class="d-block">{{ trackedData.date }}</strong>
    <div
      class="progress mt-3"
      :title="timeToText"
      style="height: 10px; max-width: 300px"
    >
      <div
        class="progress-bar"
        role="progressbar"
        :style="{ width: trackedPercentage + '%' }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <span class="daily-tracker__duration text-muted d-block mt-2">{{
      timeToText
    }}</span>
  </div>
</template>
<script>
export default {
  props: {
    trackedData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    trackedPercentage() {
      return (this.trackedData.duration / 28800) * 100;
    },
    timeToText() {
      return this.toHoursAndMinutes(this.trackedData.duration);
    },
  },
  methods: {
    toHoursAndMinutes(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours} hours ${minutes} minutes`;
    },
  },
};
</script>
<style lang="scss" scoped>
.daily-tracker {
  border-radius: $size-10;
  min-width: 150px;
  max-width: 150px;
  height: 138px;
  &__day {
    font-size: 0.75rem;
  }
  &__duration {
    font-size: 0.75rem;
  }
}
</style>
