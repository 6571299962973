<template>
  <b-card class="time-worked" :title="title" title-tag="h2">
    <div class="time-worked__tracked-time d-flex align-items-end">
      <div class="mt-1 mr-3">
        <h3 class="text-primary">{{ timeToText }}</h3>
      </div>
      <div class="mr-3">
        <span class="fs-5">Tracked time</span>
      </div>
      <div class="flex-grow-1 pb-1">
        <div
          class="progress"
          style="height: 10px; max-width: 300px"
          title="135 hrs 3 mins"
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: trackedPercentage + '%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <div class="time-worked__daily-tracks d-flex overflow-auto">
      <DailyTracker
        v-for="(daily, i) in sortedDailyTracks"
        :key="i"
        :tracked-data="daily"
      />
    </div>
  </b-card>
</template>
<script>
import DailyTracker from "./DailyTracker.vue";
import { toHoursAndMinutes } from "../../utils";
export default {
  components: {
    DailyTracker,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dailyTracks: {
      type: Array,
      default: () => [],
    },
    total_duration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    trackedPercentage() {
      // total duration is 633600. 176hours in secs
      let percent = Math.ceil((this.total_duration / 633600) * 100);

      return percent < 100 ? percent : 100;
    },
    timeToText() {
      return toHoursAndMinutes(this.total_duration);
    },
    sortedDailyTracks() {
      const copy = [...this.dailyTracks]; // Create a copy of the array to avoid any side effect in the sorted array
      return copy.sort((a, b) => {
        // here date is one of the field property in dailyTracks array
        return new Date(a.date) - new Date(b.date);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.time-worked {
  border-radius: $size-10;
  h2 {
    font-size: 1.875rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
  &__daily-tracks {
    padding: 30px 0 20px 0;
  }
}
</style>
