<template>
  <b-modal id="task_modal" modal-class="task-modal" hide-footer>
    <template #modal-title>
      <span
        class="task-modal__icon d-block d-flex align-items-center justify-content-center"
      >
        <CalendarIcon />
      </span>
    </template>

    <p v-if="task.entries" class="mb-5">
      <span class="d-block task-modal__title">{{
        getDay(task.created_at)
      }}</span>
      <strong class="task-modal__date">{{
        task.entries[task.entries.length - 1].start_formatted
      }}</strong>
    </p>
    <p class="mb-5">
      <span class="d-block task-modal__title mb-2">Task Name</span>
      <span class="task-modal__task">{{ task.name }}</span>
    </p>
    <div>
      <span class="d-block task-modal__title mb-2">Time spent on task</span>
      <div v-if="task.entries" class="w-25">
        <div
          class="progress mb-2"
          style="height: 10px"
          :title="timeToText(task.entries[task.entries.length - 1].duration)"
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: '25%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <span class="mt-5">
          {{ timeToText(task.entries[task.entries.length - 1].duration) }}</span
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
import CalendarIcon from "../svg/CalendarIcon";
import { toHoursAndMinutes } from "../../utils";
export default {
  name: "TaskModal",
  components: {
    CalendarIcon,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  methods: {
    timeToText(duration) {
      return toHoursAndMinutes(duration);
    },
    getDay(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        weekday: "long",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.task-modal {
  &__icon {
    background-color: $grey-tint-3;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  &__date {
    color: $dark-blue;
    font-size: 1.125rem;
  }
  &__title {
    color: $faint-blue;
  }
  &__task {
    color: $dark-blue;
    font-size: 1.125rem;
  }
}
</style>
