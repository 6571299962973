<template>
  <div class="time-adder card p-3">
    <form
      class="d-flex justify-content-between align-items-center overflow-auto"
    >
      <div class="mr-3 m-min-w-400">
        <b-form-input
          v-model.trim="name"
          placeholder="What are you working on?"
        ></b-form-input>
      </div>
      <div class="m-min-w-200 mr-3">
        <b-form-select
          v-model="project_id"
          :options="filteredProjects"
        ></b-form-select>
      </div>
      <div class="time-adder__tenure m-min-w-300">
        <div v-if="!show" @click="showTimeAndDate()">
          <b-button
            variant="light"
            class="time-adder__button px-4 overflow-auto"
          >
            What have you worked on?
          </b-button>
        </div>

        <!-- Working on making the project option empty after selection -->
        <div v-else class="d-flex justify-content-between align-items-center">
          <div>
            <input
              type="time"
              name="time"
              aria-placeholder="9:00 AM"
              placeholder="9:00 AM"
              v-model="from"
            />
          </div>

          <strong class="mx-1">-</strong>

          <input
            class="time-adder__time"
            type="time"
            name="time"
            aria-placeholder="4:00 PM"
            placeholder="5:00 PM"
            v-model="to"
          />

          <MDateInput v-model="date" />
        </div>
      </div>
      <div class="time-adder__actions">
        <button
          class="btn btn-timer-action mr-2"
          title="Start"
          :disabled="disableAddBtn"
          type="button"
          @click="addTime"
        >
          <PlayPauseIcon variant="primary" />
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import MDateInput from "./MDateInput.vue";
import PlayPauseIcon from "../svg/PlayPauseIcon.vue";

import moment from "moment";
export default {
  name: "TimeAdder",
  components: {
    MDateInput,
    PlayPauseIcon,
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      project_id: "",
      from: "",
      to: "",
      date: null,
      show: false,
    };
  },
  computed: {
    filteredProjects() {
      let projects = this.projects.map((item) => ({
        value: item.id,
        text: item.name,
      }));
      return [{ value: "", text: "Please select a project" }, ...projects];
    },
    disableAddBtn() {
      return this.name === "" || this.date === "";
    },
    formattedFrom() {
      return this.from && moment(this.from, "HH:mm").format("h:mm A");
    },
    formattedTo() {
      return this.to && moment(this.to, "HH:mm").format("h:mm A");
    },
  },
  methods: {
    addTime() {
      this.$emit("added", {
        name: this.name,
        date: this.date,
        project_id: this.project_id,
        to: this.formattedTo,
        from: this.formattedFrom,
      });
      this.name = this.project_id = "";
      this.show = false;
    },
    showTimeAndDate() {
      return (this.show = true);
    },
  },
};
</script>
<style lang="scss" scoped>
.time-adder {
  border-radius: $size-10;
  &__button {
    border: 1px solid rgb(211, 207, 207);
  }
  &__tenure {
    .form-control {
      height: 25px;
      width: 56px;
      font-size: 0.75rem;
      line-height: 1rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      border: 1px solid $grey-tint-3;
      &[type="date"] {
        width: 130px;
        border: none;
      }
    }
  }
  &__time {
    margin-right: 15px;
  }
  &__actions {
    min-width: 70px;
  }
}
</style>
