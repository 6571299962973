<template>
  <div class="m-date-input position-relative">
    <!-- this should have been hidden but showPicker() is still not supported fully everywhere -->
    <input class="form-control" type="date" @input="handleInput" />
    <CalendarIcon class="m-date-input__calendar-icon" />
  </div>
</template>
<script>
import CalendarIcon from "../svg/CalendarIcon.vue";
export default {
  name: "MDateInput",
  components: {
    CalendarIcon,
  },
  // props: {
  //   value: {
  //     type: String,
  //     default: "",
  //   },
  // },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.m-date-input {
  .form-control {
    // border: none;
    padding-left: 18px;
    font-size: $regular-size;
    border: none;
  }
  &__calendar-icon {
    @include set-position(absolute, top, left, 9px, 0);
  }
}
</style>
