<template>
  <div>
    <div class="task-list">
      <div class="task-list__header">
        <div class="task-list__row d-flex">
          <div class="flex-grow-1">Tasks</div>
          <div>Duration</div>
        </div>
      </div>
      <TaskListItem
        v-for="item in items"
        :task="item"
        :key="item.id"
        @opened="openTask"
        @toggled="toggleTime"
      />
    </div>
    <TaskModal :task="openedTask" />
  </div>
</template>
<script>
import TaskListItem from "../../components/timesheet/TaskListItem.vue";

import TaskModal from "../../components/timesheet/TaskModal.vue";
import { mapActions } from "vuex";

export default {
  name: "TaskList",
  components: {
    TaskListItem,
    TaskModal,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openedTask: {},
      types: ["play", "pause"],
    };
  },

  methods: {
    ...mapActions({
      toggleTime: "timesheet/toggleTime",
    }),

    openTask(task) {
      this.openedTask = task;
      this.$bvModal.show("task_modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.task-list {
  &__header {
    font-size: $regular-size;
    color: $grey-tint-2;
    padding: 1.375rem 2.187rem;
    border-bottom: 1px solid $grey-tint-3;
  }
  &__body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    .task-list__data:last-child {
      font-size: $regular-size;
    }
  }
}
</style>
