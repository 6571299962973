<template>
  <div class="task-list__body">
    <div
      class="task-list__row d-flex p-4 align-items-end border-bottom animate__animated animate__bounceInLeft animate__slow m-cursor-pointer"
      @click="openTask(task)"
    >
      <div class="task-list__data flex-grow-1 mr-3">
        <div class="d-flex mb-3">
          <button class="btn btn-timer-action mr-3" @click.stop="toggleTask()">
            <PlayPauseIcon variant="primary" :type="type" />
          </button>

          <span>
            {{ task.name }},
            <strong>{{
              task.entries[task.entries.length - 1].start_formatted
            }}</strong>
          </span>
        </div>
        <div class="pl-5">
          <div class="progress" style="height: 10px" :title="timeToText">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: `${percent}%` }"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div class="task-list__data">
        {{ timeToText }}
      </div>
    </div>
  </div>
</template>
<script>
import PlayPauseIcon from "../../components/svg/PlayPauseIcon.vue";
import { toHoursAndMinutes } from "../../utils";

export default {
  name: "TaskListItem",
  components: {
    PlayPauseIcon,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      types: ["pause", "play"],
    };
  },

  computed: {
    duration() {
      let durations = this.task.entries.map((timeE) => {
        return typeof timeE.duration === "number" ? timeE.duration : 0;
      });

      let sumOfDurations = durations.reduce((a, b) => {
        return typeof a === "number" && typeof b === "number" ? a + b : a;
      }, 0);

      // let duration =
      //   sumOfDurations + (typeof this.count === "number" ? this.count : 0);

      return sumOfDurations;
    },
    timeToText() {
      return toHoursAndMinutes(this.duration);
    },

    percent() {
      // total duration is 28800. 8hours in secs
      let percent = Math.ceil((this.duration / 28800) * 100);

      return percent < 100 ? percent : 100;
    },
    type() {
      return this.task?.status === 0 ? "pause" : "play";
    },
  },

  methods: {
    openTask(task) {
      console.log("task", task);
      this.$emit("opened", task);
    },
    toggleTask() {
      this.$emit("toggled", this.task.uuid);
      this.$store.dispatch("timesheet/fetchTimesheet");
    },
  },
};
</script>
<style lang="scss" scoped>
.task-list {
  &__header {
    font-size: $regular-size;
    color: $grey-tint-2;
    padding: 1.375rem 2.187rem;
    border-bottom: 1px solid $grey-tint-3;
  }
  &__body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    .task-list__data:last-child {
      font-size: $regular-size;
    }
  }
}
</style>
