<template>
  <div class="timesheet">
    <div class="dashboard-summary">
      <TimeWorked
        :daily-tracks="recentTime"
        title="Time Worked"
        :total_duration="totalDuration"
      />
    </div>
    <div class="dashboard-content py-4">
      <TimeAdder :projects="projects" :addTime="addTime" @added="addToList" />
      <div class="timesheet__list mt-4">
        <TaskList :items="timesheet" />
        <div
          class="m-pagination overflow-auto d-flex p-4 justify-content-between align-items-center"
        >
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ totalRows }}
          </span>
          <b-pagination
            v-model="currentPage"
            :per-page="meta.per_page"
            :total-rows="meta.total"
            align="right"
            pills
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TimeWorked from "../../components/timesheet/TimeWorked";
import TimeAdder from "../../components/timesheet/TimeAdder";
import TaskList from "../../components/timesheet/TaskList.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Timesheet",
  components: {
    TimeWorked,
    TimeAdder,
    TaskList,
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      interval: null,
      isFetched: false,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.timesheet.projects,
      timesheet: (state) => state.timesheet.timesheet,
      recentTime: (state) => state.timesheet.recent_time,
      totalDuration: (state) => state.timesheet.total_duration,
      meta: (state) => state.timesheet.meta,
    }),
    totalRows() {
      return this.meta.last_page;
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage) {
        this.fetchTimesheet(this.currentPage);
      }
    },
  },

  created() {
    this.fetchProjects();
    this.fetchRecentTime();
    this.fetchTimesheet();

    this.interval = setInterval(() => {
      this.fetchTimesheet();
      this.fetchRecentTime();
    }, 60 * 1000);
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },

  methods: {
    ...mapActions({
      fetchProjects: "timesheet/fetchProjects",
      fetchTimesheet: "timesheet/fetchTimesheet",
      addTime: "timesheet/addTime",
      fetchRecentTime: "timesheet/fetchRecentTime",
      toggleTime: "timesheet/toggleTime",
    }),
    async addToList(item) {
      try {
        const { data } = await this.addTime(item);
        this.$store.dispatch("timesheet/fetchTimesheet");
        this.$store.dispatch("timesheet/fetchRecentTime");
        this.$toast.success("Task added successfully");
      } catch (error) {
        console.log({ error });
        this.$toast.error(error);
      }
    },
  },
};
</script>
<style lang="scss">
.timesheet {
  &__list {
    background-color: $body-bg;
    border-radius: $size-10;
    border: 1px solid $grey-tint-3;
  }
}
</style>
